import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import P from "../components/P";

function ApplicationSubmittedPage() {
  return (
    <Layout>
      <SEO title="Application" />
      <header>
        <h1>Application</h1>
      </header>
      <P>
        Your application has been submitted! We will be in touch with you
        shortly.
      </P>
    </Layout>
  );
}

export default ApplicationSubmittedPage;
